<template>
  <div>
    <b-skeleton-wrapper :loading="loading">
      <div class="col-10 offset-1">
        <b-skeleton width="25%" animation="wave" class="mt-4 mb-4"></b-skeleton>

        <b-skeleton-table
          :rows="5"
          :columns="4"
          :table-props="{ bordered: true, striped: true }"
        ></b-skeleton-table>
      </div>
    </b-skeleton-wrapper>
    <app-import-contact-table
      v-if="loading == true"
      :getItems="this.importLine"
      :payLoad="this.importLinePayload"
      :totaItems="this.totalImportLine"
    >
    </app-import-contact-table>
  </div>
</template>

<script>
import AppImportContactTable from "../components/AppImportContactTable.vue";
export default {
  components: { AppImportContactTable },
  name: "DettagliImportazione",

  data() {
    return {
      loading: false,
      importLine: [],
      totalImportLine: 0,
      importLinePayload: [],
    };
  },

  created() {
    this.listImportLine();
    this.listImportLinePayload();
  },

  methods: {
    async listImportLine() {
      try {
        await this.$store
          .dispatch("contactsImports/getContactsImportsById", {
            id: this.$route.query.id,
          })
          .then(() => {
            this.importLine = this.$store.getters[
              "contactsImports/listContacts"
            ];
            this.importLinePayload = this.$store.getters[
              "contactsImports/listContactsPayload"
            ];
            console.log(this.importLine.length);
            /* console.log(this.importLinePayload); */
            this.totalImportLine = this.importLine.length;
            this.loading = true;
            return this.importLine;
          });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error listImportLine" });
      }
    },

    listImportLinePayload() {
      console.log(this.importLinePayload);
      return this.importLinePayload;
    },
  },
};
</script>
